import * as React from "react"
import Layout from '../components/BasicLayout'
import SEO from "../components/seo"
import tw, { styled, css } from "twin.macro"
import { Link } from "gatsby"
import {cssNavBaseText,cssNavButton} from '../utils/cssVars'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div tw="flex items-center justify-center h-screen max-w-2xl m-6 md:mx-auto overflow-hidden">
    <div tw=" p-8 rounded-lg">
            <h1 tw="p-8 block mt-2 text-4xl font-semibold text-gray-800 dark:text-white">
              You might be lost!
            </h1>

            <div tw="flex flex-col justify-center h-full" >
        {/* <HomeButton  tw="mt-auto" isPrimary destination={"/"}>Head home!</HomeButton> */}
      <div tw="mt-auto px-3 py-1 mx-auto flex[0 1 auto]"  
                css={[cssNavBaseText, cssNavButton, css`box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.25);`]}>
                  <Link to="/">Head Home</Link>
                </div>
                </div>
          </div>
                  </div>
  </Layout>
)
export default NotFoundPage
